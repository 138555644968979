<template>
    <custom-table
        :filter_settings="essayFilters"
        :filter_items="filterItems"
        :page_filters="page_filters"
        :headers_items="headerItems"
        :toolbar_settings="toolbarSettings"
        :links="links"
        :loading="loading || !essays"
        :content-type="contentType"
        :filters="filters"
        :items="essays"
        :options="tableOptions"
        @getData="getList($event)"
        @selectAllCheckbox="selectAllCheckbox($event)"
    />
</template>

<script>
import contentBaseMixin from '@/mixins/content/contentBaseMixin'
import { essayFilters } from '@/helpers/filters-settings/essay-filters'
import { headerItems } from '@/helpers/constants/essay-page/content-table-header-items'
import { FilterItems, Filters } from '@/helpers/constants/essay-page/filters'
import { toolbarSettings } from '@/helpers/constants/essay-page/toolbar-settings'
import { Links } from '@/helpers/constants/essay-page/links'

import {
    ACTION_GET_ESSAY_LIST
} from '@/store/modules/content/content-list/action-types'
import {
    ACTION_GET_CATEGORY_LIST_FOR_SELECT
} from '@/store/modules/content/content-category/action-types'
import { mapContentListFields } from '@/store/modules/content/content-list'
import { createNamespacedHelpers } from 'vuex';
import { debounce, isEqual } from 'lodash';

const {
    mapActions: mapContentСategoriesActions
} = createNamespacedHelpers('contentCategory');
const {
    mapActions: mapContentListActions
} = createNamespacedHelpers('contentList');

export default {
    name: 'EssayPages',
    mixins: [
        contentBaseMixin
    ],
    data() {
        return {
            tableOptions: {
                filters: true,
                content: true,
                toolbar: true,
                hiddenModals: true
            },
            currentRoute: 'posts-list-essay',
            filters: {}
        }
    },
    computed: {
        ...mapContentListFields([
            'essays'
        ]),
        essayFilters() {
            return essayFilters
        },
        headerItems() {
            return headerItems
        },
        filterItems() {
            return FilterItems
        },
        allToolbarSettings() {
            return toolbarSettings
        },
        // need for base content mixin because each page has different items
        items() {
            return this.essays
        },
        links() {
            return Links
        },
        page_filters() {
            return Filters
        }
    },
    watch: {
        '$route.query': debounce(function (val) {
            if (this.$route.name !== 'posts-list-essay') return
            if (isEqual(this.$route.query, this.filters)) return
            this.filters = {
                ...val
            }
            this.getList(val)
        }, 50)
    },
    activated() {
        this[ACTION_GET_CATEGORY_LIST_FOR_SELECT]({
            path: 'essay'
        })
    },
    methods: {
        ...mapContentСategoriesActions([
            ACTION_GET_CATEGORY_LIST_FOR_SELECT
        ]),
        ...mapContentListActions([
            ACTION_GET_ESSAY_LIST
        ]),
        getList(query) {
            this[ACTION_GET_ESSAY_LIST]({ ...query })
        }
    }
}
</script>
